<template>
  <div
    v-if="!layoutMobile"
    class="create-shop-page min-h-screen px-[24px] py-[21px] pb-[250px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.stores_creation") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
    </div>
    <div
      class="w-[70%] min-h-[75vh] mt-[24px] mx-auto border-[1px] border-solid border-[#D0D5DD] rounded-[12px] p-[24px] bg-white"
    >
      <div class="header flex justify-between items-center gap-[20px]">
        <div class="shop-name">{{ $t("common.stores_creation") }}</div>
      </div>
      <div class="content flex flex-col justify-start items-start gap-[20px] mt-[20px]">
        <div class="box-avatar flex-wrap flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_logo_new") }}</label
          >

          <div class="box-upload flex justify-start items-center gap-[32px]">
            <div class="avatar w-[80px] h-[80px] relative">
              <img
                @click="handleUploadAvatar"
                id="avatarShop"
                :src="shopAvatarRender"
                alt=""
                @error="(e) => handleImageError(e, 'shop')"
                @load="(e) => handleAvatarLoadingFinish(e)"
                class="w-[80px] h-[80px] cursor-pointer rounded-full border-solid border-[#EAECF0] border-[1px] object-cover"
              />
              <button
                @click="handleUploadAvatar"
                :class="{
                  'absolute cursor-pointer flex justify-center items-center right-0 bottom-0 w-[28px] h-[28px] rounded-full p-[6px] bg-[#F38744]': true,
                  '!bg-white': loadingAvatarShop,
                }"
              >
                <a-spin
                  v-if="loadingAvatarShop"
                  class="flex justify-center items-center w-[20px] h-[20px]"
                />
                <span v-else v-html="CameraWhiteIcon"></span>
              </button>
              <input
                class="hidden"
                type="file"
                id="shop-avatar"
                accept="image/*"
                @change="handleEventChangeFileAvatar"
              />
            </div>
            <button
              @click="handleDeleteShopAvatar"
              :class="{
                'flex justify-center items-center gap-[8px] rounded-[8px] py-[12px] px-[16px] border-[1px] border-[#ADB5C2] cursor-default border-solid': true,
                '!border-delete-text cursor-pointer': shopAvatarFileUpload !== null,
              }"
            >
              <span
                class="flex justify-center items-center"
                v-html="shopAvatarFileUpload !== null ? TrashFilledIcon : TrashGrayIcon"
              ></span>
              <span
                :class="{
                  'flex justify-center items-center whitespace-nowrap text-[#ADB5C2] noto500 text-[14px] leading-[18px]': true,
                  '!text-delete-text': shopAvatarFileUpload !== null,
                }"
                >{{ $t("common.logo_removal") }}
              </span>
            </button>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_name") }}</label
          >
          <input
            v-model="shopInfo.name"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_telephone_number") }}</label
          >
          <input
            v-model="shopInfo.phone"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.address") }}</label
          >
          <input
            v-model="shopInfo.address"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]">
            {{ $t("common.area") }}</label
          >
          <a-select
            :placeholder="areaShop"
            :options="areaList"
            @change="handleSelectArea"
            showSearch
            class="flex-1 rounded-[8px] px-[14px] py-[10px] bg-white outline-none"
          >
            <template #suffixIcon> <span v-html="ArrowDownOrangeIconn"></span> </template
          ></a-select>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.account_creation_date") }}</label
          >
          <label
            class="flex-1 flex justify-left items-center h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          >
            <a-date-picker
              class="w-full h-full"
              :format="dateFormat"
              picker="date"
              :allowClear="false"
              :placeholder="''"
              v-model:value="dateSelected"
              :locale="locale"
            >
              <template #suffixIcon>
                <span v-html="DateIcon"></span>
              </template>
            </a-date-picker>
          </label>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.email_address") }}</label
          >
          <input
            v-model="shopInfo.email"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-image-shop flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.logo_removal") }}</label
          >
          <div
            class="box-upload-images flex-wrap flex flex-col justify-start items-start gap-[8px]"
          >
            <button
              @click="handleUploadImages"
              class="flex justify-center items-center gap-[8px] w-[140px] h-[40px] px-[16px] py-[12px] border-[1px] border-solid border-[#F38744] rounded-[8px]"
            >
              <span v-html="FileUploadIconOrange"></span>
              <span
                class="noto500 text-[14px] leading-[18px] text-[#F38744] whitespace-nowrap"
                >{{ $t("common.upload") }}</span
              >
            </button>
            <input
              id="shop-image"
              class="hidden"
              type="file"
              multiple="multiple"
              accept="image/*"
              @change="handleEventChangeFileImages"
            />
            <div class="flex flex-wrap justify-start items-start gap-[12px]">
              <div
                v-for="(item, index) in shopImageRenders"
                :key="index"
                class="relative w-[130px] h-[80px] rounded-[8px] overflow-hidden"
              >
                <img
                  :src="item.src"
                  alt=""
                  style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  class="min-w-[130px] w-[130px] h-[80px] rounded-[8px] object-cover border-solid border-[1px] border-[#D0D5DD]"
                />
                <button
                  @click="handleDeleteShopImage(item.type, item.name, index)"
                  class="absolute bottom-[4px] right-[4px] w-[28px] h-[28px] rounded-[4px] p-[6px] border-[1px] border-white border-solid bg-[#D0D5DD]"
                >
                  <span v-html="TrashWhiteIcon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-end items-center gap-[8px]">
          <button
            @click="handleCreateShop"
            class="w-[120px] h-[40px] rounded-[8px] bg-[#F38744] flex justify-center items-center gap-[8px]"
          >
            <span v-html="SaveWhiteIcon"></span>
            <span class="text-white">{{ $t("common.create") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="create-shop-page-mobile min-h-screen px-[24px] py-[21px] pb-[250px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.stores_creation") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
    </div>
    <div
      class="w-[100%] min-h-[75vh] mt-[24px] mx-auto border-[1px] border-solid border-[#D0D5DD] rounded-[12px] p-[24px] bg-white"
    >
      <div class="header w-full flex justify-start items-center gap-[20px]">
        <div class="shop-name">{{ $t("common.stores_creation") }}</div>
      </div>
      <div
        class="content w-full flex flex-col justify-start items-start gap-[20px] mt-[20px]"
      >
        <div class="box-avatar w-full flex justify-between items-center gap-[8px]">
          <label
            class="min-w-[144px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_logo_new") }}</label
          >
          <div class="box-upload flex justify-start items-center gap-[12px]">
            <div class="avatar w-[80px] h-[80px] relative">
              <img
                @click="handleUploadAvatar"
                id="avatarShop"
                :src="shopAvatarRender"
                alt=""
                @load="(e) => handleAvatarLoadingFinish(e)"
                @error="(e) => handleImageError(e, 'shop')"
                class="w-[80px] h-[80px] cursor-pointer rounded-full border-solid border-[#EAECF0] border-[1px] object-cover"
              />
              <button
                @click="handleUploadAvatar"
                :class="{
                  'absolute cursor-pointer flex justify-center items-center right-0 bottom-0 w-[28px] h-[28px] rounded-full p-[6px] bg-[#F38744]': true,
                  '!bg-white': loadingAvatarShop,
                }"
              >
                <a-spin
                  v-if="loadingAvatarShop"
                  class="flex justify-center items-center w-[20px] h-[20px]"
                />
                <span v-else v-html="CameraWhiteIcon"></span>
              </button>
              <input
                class="hidden"
                type="file"
                id="shop-avatar"
                accept="image/*"
                @change="handleEventChangeFileAvatar"
              />
            </div>
            <button
              @click="handleDeleteShopAvatar"
              :class="{
                'flex justify-center items-center w-[32px] h-[32px] gap-[8px] rounded-[8px] py-[12px] px-[16px] border-[1px] border-[#ADB5C2] cursor-default border-solid': true,
                '!border-delete-text cursor-pointer': shopAvatarFileUpload !== null,
              }"
            >
              <span
                class="flex justify-center items-center"
                v-html="shopAvatarFileUpload !== null ? TrashFilledIcon : TrashGrayIcon"
              ></span>
            </button>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_name") }}</label
          >
          <input
            v-model="shopInfo.name"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_telephone_number") }}</label
          >
          <input
            v-model="shopInfo.phone"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.address") }}</label
          >
          <input
            v-model="shopInfo.address"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]">
            {{ $t("common.area") }}</label
          >
          <a-select
            :options="areaList"
            @change="handleSelectedArea"
            showSearch
            class="flex-1 w-full rounded-[8px] px-[14px] py-[10px] bg-white outline-none"
          >
            <template #suffixIcon> <span v-html="ArrowDownOrangeIconn"></span> </template
          ></a-select>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.account_creation_date") }}</label
          >
          <label
            class="flex-1 w-full flex justify-left items-center h-[40px] max-h-[40px] overflow-hidden rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          >
            <a-date-picker
              class="w-full h-full"
              :format="dateFormat"
              picker="date"
              :allowClear="false"
              :placeholder="''"
              v-model:value="dateSelected"
              :locale="locale"
            >
              <template #suffixIcon>
                <span v-html="DateIcon"></span>
              </template>
            </a-date-picker>
          </label>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input w-full flex flex-col justify-start items-start gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.email_address") }}</label
          >
          <input
            v-model="shopInfo.email"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div
          class="box-image-shop w-full flex flex-col justify-start items-start gap-[8px]"
        >
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.logo_removal") }}</label
          >
          <div
            class="box-upload-images w-full flex flex-col justify-start items-start gap-[8px]"
          >
            <button
              @click="handleUploadImages"
              class="flex justify-center items-center gap-[8px] w-full h-[40px] px-[16px] py-[12px] mb-[10px] border-[1px] border-solid border-[#F38744] rounded-[8px]"
            >
              <span v-html="FileUploadIconOrange"></span>
              <span
                class="noto500 text-[14px] leading-[18px] text-[#F38744] whitespace-nowrap"
                >{{ $t("common.upload") }}</span
              >
            </button>
            <input
              id="shop-image"
              class="hidden"
              type="file"
              multiple="multiple"
              accept="image/*"
              @change="handleEventChangeFileImages"
            />
            <div class="m-auto">
              <div class="flex flex-wrap justify-center items-start gap-[12px]">
                <div
                  v-for="(item, index) in shopImageRenders"
                  :key="index"
                  class="relative w-[120px] h-[80px] rounded-[8px] overflow-hidden border-solid border-[1px] border-[#D0D5DD]"
                >
                  <img
                    :src="item.src"
                    alt=""
                    style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    class="min-w-[135px] w-[130px] h-[80px] rounded-[8px] object-cover border-none"
                  />
                  <button
                    @click="handleDeleteShopImage(item.type, item.name, index)"
                    class="absolute bottom-[4px] right-[4px] w-[28px] h-[28px] rounded-[4px] p-[6px] border-[1px] border-white border-solid bg-[#D0D5DD]"
                  >
                    <span v-html="TrashWhiteIcon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-end items-center gap-[8px]">
          <button
            @click="handleCreateShop"
            class="w-full h-[40px] rounded-[8px] bg-[#F38744] flex justify-center items-center gap-[8px]"
          >
            <span v-html="SaveWhiteIcon"></span>
            <span class="text-white">{{ $t("common.create") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  ArrowDownOrangeIconn,
  CameraWhiteIcon,
  FileUploadIconOrange,
  SaveWhiteIcon,
  TrashGrayIcon,
  TrashWhiteIcon,
} from "../../../assets/constant/svg";
import {
  addModal,
  createFormDataFromObject,
  formatDateForEn,
  formatDateForJapanese,
  formatServerDateToJapaneseYYYYMMDD,
  getAvatarShopByName,
  getCurrentDate,
  handleImageError,
} from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import { TrashFilledIcon } from "../../../assets/constant/constant";
import locale from "ant-design-vue/es/date-picker/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
const dateFormat = "YYYY年M月D日";
dayjs.locale("ja");
const store = useStore();
const router = useRouter();
const dateSelected = ref(dayjs(getCurrentDate(), "YYYY-MM-DD"));
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const routes = computed(() => [
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/store-management",
    breadcrumbName: t("common.store_management"),
  },
  {
    path: "/store-management/create-store",
    breadcrumbName: t("common.stores_creation"),
  },
]);
const shopInfo = reactive({
  name: "",
  phone: "",
  email: "",
  address: "",
  location: "",
});

// Image variables
const shopImageRenders = ref([]);
const shopAvatarRender = ref(getAvatarShopByName(""));
const shopImageFileUpload = ref([]);
const shopAvatarFileUpload = ref(null);
const loadingAvatarShop = ref(true);

// calculate the image
const multiImage = computed(() => {
  return [shopAvatarFileUpload.value]
    .concat(shopImageFileUpload.value)
    ?.filter((file) => {
      return file !== null && file !== undefined && file !== "" && file;
    });
});

// Handle avatar image upload (event onchange from input to activate function)
const handleEventChangeFileAvatar = (event) => {
  const fileUpload = event.target.files[0];
  shopAvatarFileUpload.value = fileUpload;
  const fileRender = URL.createObjectURL(fileUpload);
  shopAvatarRender.value = fileRender;
};
const handleDeleteShopAvatar = () => {
  if (!loadingAvatarShop.value && shopAvatarFileUpload !== null) {
    addModal(ConfirmModal, {
      title: t("common.delete_photo"),
      textBtnClose: t("common.cancel_delete"),
      textBtnConfirm: t("common.delete_photo"),
      onConfirm: () => {
        shopAvatarFileUpload.value = null;
        shopAvatarRender.value = getAvatarShopByName("");
      },
    });
  } else {
    showAlert(
      "warning",
      localStorage.getItem("lang") === "en"
        ? "Profile picture is empty"
        : "プロフィール写真が空です",
      "top-center"
    );
  }
};

// Handle shop image upload (event onchange from input to activate function)
const handleEventChangeFileImages = (event) => {
  const files = event.target.files;
  if (files) {
    Array.from(files).forEach((file) => {
      shopImageFileUpload.value.push(file);
      shopImageRenders.value.push({
        src: URL.createObjectURL(file),
        type: "new",
        name: URL.createObjectURL(file),
      });
    });
  }
};

const handleDeleteShopImage = (type, name, index) => {
  shopImageRenders.value.splice(index, 1);
  shopImageFileUpload.value.splice(index, 1);
};

// Handle Image action & UI
const handleAvatarLoadingFinish = () => {
  loadingAvatarShop.value = false;
};
const handleUploadAvatar = () => {
  if (document.getElementById("shop-avatar")) {
    document.getElementById("shop-avatar").click();
  }
};
const handleUploadImages = () => {
  if (document.getElementById("shop-image")) {
    document.getElementById("shop-image").click();
  }
};
// Location
const areaList = computed(() => store.getters.areaListGetters());
const handleSelectedArea = (value) => {
  shopInfo.location = areaList.value.find((area) => area.value === value)?.id;
};

const handleCreateShop = () => {
  addModal(ConfirmModal, {
    title: t("common.do_you_create_this_store"),
    textBtnConfirm: t("common.create"),
    textBtnClose: t("common.cancel_delete"),
    onConfirm: () => {
      const data = {
        ...shopInfo,
        checkNotAvatar: 1,
        multiImage: multiImage.value,
        createdAt: dateSelected.value,
      };
      if (shopAvatarFileUpload.value !== null && shopAvatarRender.value !== null) {
        delete data.checkNotAvatar;
      }
      if (multiImage.value.length <= 0) {
        delete data.multiImage;
      }
      const formData = createFormDataFromObject(data);
      store.dispatch("createShop", {
        shopInfo: formData,
        idShop: -1,
        onSuccess: () => {
          store.dispatch("getAllShopAllStatus");
          setTimeout(() => {
            router.replace("/store-management");
          }, 2000);
        },
      });
    },
  });
};
onMounted(() => {
  if (areaList && areaList.value.length <= 0) {
    store.dispatch("getAreaList");
  }
});
</script>
<styles lang="scss">
.create-shop-page {
  input {
    font-family: Noto400;
    font-size: 14px;
    line-height: 22px;
    color: #1d2939;
  }
  .ant-picker {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .ant-picker-input {
    input {
      text-align: left !important;
    }
  }
}
.create-shop-page-mobile {
  input {
    width: 100% !important;
    font-family: Noto400;
    font-size: 14px;
    line-height: 22px;
    color: #1d2939;
  }
  .ant-picker {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .ant-picker-input {
    input {
      text-align: left !important;
    }
  }
}
</styles>
