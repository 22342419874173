<template>
  <div
    :class="{ 'sidebar-item': true, 'sidebar-item-focus': sidebarFocus === index }"
    v-on:click="handleUpdateFocusSideBar"
  >
    <router-link :to="link" exact tag="li" class="h-full">
      <span class="icon" v-html="icon"></span>
      <span class="name">{{ title }}</span>
    </router-link>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  icon: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    default: "/",
  },
  arrayChild: {
    type: Array,
    default: [],
  },
});

const sidebarFocus = computed(() => store.state.sidebarFocus);
const handleUpdateFocusSideBar = () => {
  store.dispatch("setSidebarFocus", props.index);
  if (!router.currentRoute.value.fullPath.includes(props?.link)) {
    router.push(`${props.link}`);
  }
  if(layoutMobile.value){
    store.dispatch("setStatusHideSidebar", true);
  }
};
</script>

<style lang="scss">
.sidebar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-left: 4px solid transparent;
  &:hover {
    background-color: #344054;
    path,
    circle {
      fill: var(--color-text-light);
    }
    .name {
      color: var(--color-text-light);
    }
  }
  &:active {
    border-left: 4px solid var(--primary-color);
    path,
    circle {
      fill: var(--primary-color) !important;
    }
    .name {
      color: var(--primary-color) !important;
    }
  }
  a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-left: 16px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-text-gray);
      }
    }
    .name {
      font-size: 16px;
      font-family: "Noto500";
      color: var(--color-text-gray);
    }
  }
}
.sidebar-collapse {
  .menu-links {
    .sidebar-item {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        .name {
          display: none;
        }
      }
    }
  }
}
.sidebar-item-focus {
  border-left: 4px solid var(--primary-color);
  background-color: #182230 !important;
  path,
  circle {
    fill: var(--primary-color) !important;
  }
  .name {
    color: var(--primary-color) !important;
  }
}
</style>
