import { authActions } from './auth';
import { shopsAction } from './shop';
import { uisAction } from './ui';
import { usersAction } from './users';

export default {
  ...usersAction,
  ...authActions,
  ...uisAction,
  ...shopsAction
};
