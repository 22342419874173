<template>
  <div
    v-if="!layoutMobile"
    class="shop-item-req-appvoral bg-white min-h-[120px] rounded-[12px] px-[24px] py-[12px] flex flex-wrap justify-between items-center gap-[24px] border-solid border-[1px] border-[#D0D5DD]"
  >
    <div class="box-img">
      <img
        class="w-[80px] h-[80px] rounded-full object-cover"
        alt=""
        src=""
        @error="(e) => handleImageError(e, shop)"
      />
    </div>
    <div class="info h-[56px] flex flex-1 flex-col justify-center items-start gap-[12px]">
      <span class="noto700 text-[18px] leading-[24px] text-[#1D2939]">{{
        shortenString(shop?.name, lengthCharacterNameStore)
      }}</span>
      <span class="noto400 text-[16px] leading-[20px] text-[#475467]">{{
        shortenString(shop?.email, lengthCharacterNameStore)
      }}</span>
    </div>
    <div class="btns flex justify-center items-center flex-wrap gap-[8px]">
      <button
        @click="handleDeleteRequestCreateShop"
        class="w-[80px] h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F04438] text-white bg-[#F04438]"
      >
        <span v-html="TrashWhiteIcon"></span>
        <span>{{ $t("common.delete") }}</span>
      </button>
      <button
        @click="handleSeenDetails"
        class="w-[80px] h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white"
      >
        {{ $t("common.detail") }}
      </button>
      <button
        @click="handleCreatedShop"
        class="w-[80px] h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F38744] text-white bg-[#F38744]"
      >
        {{ $t("common.create") }}
      </button>
    </div>
  </div>
  <div
    v-else
    class="shop-item-req-appvoral bg-white min-h-[120px] rounded-[12px] px-[20px] py-[16px] flex flex-wrap justify-between items-center gap-[24px] border-solid border-[1px] border-[#D0D5DD]"
  >
    <div class="box-img w-full flex justify-between items-start">
      <img
        class="w-[80px] h-[80px] rounded-full object-cover"
        alt=""
        src=""
        @error="(e) => handleImageError(e, shop)"
      />
      <button
        @click="handleDeleteRequestCreateShop"
        class="w-[42px] h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F04438] text-white"
      >
        <span v-html="TrashRedIcon"></span>
      </button>
    </div>
    <div class="info w-full h-[56px] flex flex-col justify-start items-start gap-[8px]">
      <p class="noto700 w-full text-left text-[18px] leading-[24px] text-[#1D2939]">
        {{ shortenString(shop?.name, lengthCharacterNameStore) }}
      </p>
      <p class="noto400 w-full text-left text-[16px] leading-[20px] text-[#475467]">
        {{ shortenString(shop?.email, lengthCharacterNameStore) }}
      </p>
    </div>
    <div class="btns flex justify-center items-center flex-wrap gap-[8px]">
      <button
        @click="handleSeenDetails"
        class="flex-1 h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white"
      >
        {{ $t("common.detail") }}
      </button>
      <button
        @click="handleCreatedShop"
        class="flex-1 h-[42px] flex justify-center items-center gap-[8px] rounded-[8px] border-[1px] border-solid border-[#F38744] text-white bg-[#F38744]"
      >
        {{ $t("common.create") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { TrashRedIcon, TrashWhiteIcon } from "../../../../assets/constant/svg";
import { addModal, handleImageError, shortenString } from "../../../../helpers";
import ConfirmModal from "./ConfirmModal.vue";
import ModalSeenDetails from "./ModalSeenDetails.vue";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  shop: {
    type: Object,
    required: true,
  },
});
const areaList = computed(() => store.getters.areaListGetters());
const lengthCharacterNameStore = ref(25);
const handleCreatedShop = () => {
  router.push(`/store-management/store-request/create-store?id=${props.shop?._id}`);
};
const handleDeleteRequestCreateShop = () => {
  addModal(ConfirmModal, {
    title: t("common.do_you_want_to_delete_the_request"),
    textBtnClose: t("common.cancel_delete"),
    textBtnConfirm: t("common.delete"),
    onConfirm: () => {
      store.dispatch("deleteRequestCreateShop", {
        shopId: props.shop._id,
      });
    },
    action: "delete",
  });
};
const handleSeenDetails = () => {
  addModal(ModalSeenDetails, {
    shop: props.shop,
    areaList: areaList.value,
  });
};
const updateWindowWidth = () => {
  if (window.innerWidth > 1650) {
    lengthCharacterNameStore.value = 25;
  } else if (window.innerWidth >= 500 && window.innerWidth <= 1650) {
    lengthCharacterNameStore.value = 200;
  } else if(window.innerWidth >= 400) {
    lengthCharacterNameStore.value = 25;
  } else{
    lengthCharacterNameStore.value = 16;
  }
};
onMounted(() => {
  store.dispatch("getAreaList");
  updateWindowWidth();
  window.addEventListener("resize", updateWindowWidth);
});
onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
<style lang="scss">
.shop-item-req-appvoral {
  width: calc(100% - 12px);
  min-width: 500px;
}

@media screen and (max-width: 1300px) {
  .shop-item-req-appvoral {
    min-width: 180px !important;
    flex-direction: column;
    .info span,
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        width: 25% !important;
      }
    }
  }
}
</style>
