<template>
  <div v-if="!layoutMobile" class="user-detail-active px-[24px] py-[21px] bg-[#f9fafb]">
    <div class="flex flex-col justify-start items-start gap-[15px]">
      <div class="title">
        <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
          {{ $t("common.personal_info") }}
        </h2>
      </div>
      <div class="search-box">
        <Breadcrumd :routes="routes" />
      </div>
    </div>
    <div
      class="container mx-auto w-[70%] flex flex-col justify-start items-start gap-[20px] mt-[36px]"
    >
      <div class="title flex justify-start items-center gap-[8px]">
        <button @click="goBackRouter" v-html="ArrowLeftNotLineHos"></button>
        <div
          class="btn-back flex justify-center items-center min-w-[88px] px-[10px] h-[36px] bg-[#FDEAD7] rounded-[6px]"
        >
          <span class="text-[#F38744] text-[16px] noto500 leading-[20px]">{{
            $t("common.personal_info")
          }}</span>
        </div>
      </div>
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[27px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div class="header w-full flex justify-between items-center">
          <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
            {{ $t("common.user_information") }}
          </h3>
          <div class="btns flex justify-center items-center gap-[8px]">
            <button
              :disabled="loadCallApi"
              @click="handleDeletePermanentlyAccount"
              class="flex justify-center items-center bg-white min-w-[130px] h-[42px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
            >
              <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
                $t("common.account_deletion")
              }}</span>
            </button>
            <button
              :disabled="loadCallApi"
              @click="handleDisableAccount"
              class="flex justify-center items-center min-w-[144px] h-[42px] bg-[#F38744] rounded-[8px]"
            >
              <span class="text-white text-[14px] noto500 leading-[18px]">{{
                $t("common.account_hidden")
              }}</span>
            </button>
          </div>
        </div>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <div class="flex justify-start items-center gap-[8px]">
              <span
                v-on:click="handleUploadImage"
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.profile_picture") }}
              </span>
              <div
                class="box-avatar flex justify-start items-center gap[8px]"
                :style="{
                  pointerEvents: loadingUploadImage ? 'none' : 'auto',
                  userSelect: loadingUploadImage ? 'none' : 'auto',
                  opacity: loadingUploadImage ? '.5' : '1',
                }"
              >
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <input
                    id="user_detail_avatar_upload"
                    type="file"
                    accept="image/*"
                    class="hidden"
                    v-on:change="handleFileInputChange"
                  />
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-full"
                    style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    :src="getAvatarUserByName(userDetails.avatar)"
                    @error="handleImageError"
                    alt=""
                    id="img-avatar"
                  />
                  <button
                    disabled
                    :class="{
                      'flex justify-center items-center absolute bottom-0 right-0 w-[32px] h-[32px] rounded-full bg-[#F38744]': true,
                      '!bg-white': loadingUploadImage,
                    }"
                  >
                    <a-spin
                      v-if="loadingUploadImage"
                      class="flex justify-center items-center w-[20px] h-[20px]"
                    />
                    <span v-else class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  disabled
                  class="delete-avatar bg-white min-w-[110px] px-[10px] h-[42px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#F04438]"
                >
                  <span class="icon-trash" v-html="TrashIcon"></span>
                  <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
                    $t("common.delete_image")
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>
          <div class="user-id w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.user_id_v2") }}
              </span>
              <input
                readonly
                v-model="userDetails.userName"
                class="h-[42px] flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-name w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.username") }}
              </span>
              <input
                readonly
                v-model="userDetails.nickName"
                class="h-[42px] flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="user-detail-active px-[24px] py-[21px] bg-[#f9fafb]">
    <div class="flex flex-col justify-start items-start gap-[15px]">
      <div class="title">
        <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
          {{ $t("common.personal_info") }}
        </h2>
      </div>
      <div class="search-box">
        <Breadcrumd :routes="routes" />
      </div>
    </div>
    <div
      class="container mx-auto w-[100%] flex flex-col justify-start items-start gap-[20px] mt-[36px]"
    >
      <div class="title flex justify-start items-center gap-[8px]">
        <button @click="goBackRouter" v-html="ArrowLeftNotLineHos"></button>
        <div
          class="btn-back flex justify-center items-center min-w-[88px] px-[10px] h-[36px] bg-[#FDEAD7] rounded-[6px]"
        >
          <span class="text-[#F38744] text-[16px] noto500 leading-[20px]">{{
            $t("common.personal_info")
          }}</span>
        </div>
      </div>
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[20px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div class="header w-full flex justify-center items-center">
          <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
            {{ $t("common.user_information") }}
          </h3>
        </div>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <div class="flex flex-col justify-start items-start gap-[12px]">
              <span
                v-on:click="handleUploadImage"
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.profile_picture") }}
              </span>
              <div
                class="box-avatar flex justify-start items-center gap[8px]"
                :style="{
                  pointerEvents: loadingUploadImage ? 'none' : 'auto',
                  userSelect: loadingUploadImage ? 'none' : 'auto',
                  opacity: loadingUploadImage ? '.5' : '1',
                }"
              >
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <input
                    id="user_detail_avatar_upload"
                    type="file"
                    accept="image/*"
                    class="hidden"
                    v-on:change="handleFileInputChange"
                  />
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-full"
                    style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    :src="getAvatarUserByName(userDetails.avatar)"
                    @error="handleImageError"
                    alt=""
                    id="img-avatar"
                  />
                  <button
                    disabled
                    :class="{
                      'flex justify-center items-center absolute bottom-0 right-0 w-[32px] h-[32px] rounded-full bg-[#F38744]': true,
                      '!bg-white': loadingUploadImage,
                    }"
                  >
                    <a-spin
                      v-if="loadingUploadImage"
                      class="flex justify-center items-center w-[20px] h-[20px]"
                    />
                    <span v-else class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  disabled
                  class="delete-avatar bg-white min-w-[32px] px-[10px] h-[32px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#F04438]"
                >
                  <span class="icon-trash" v-html="TrashIcon"></span>
                </button>
              </div>
            </div>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>
          <div class="user-id w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.user_id_v2") }}
              </span>
              <input
                readonly
                v-model="userDetails.userName"
                class="min-h-[42px] h-[42px] w-full flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-name w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t("common.username") }}
              </span>
              <input
                readonly
                v-model="userDetails.nickName"
                class="min-h-[42px] h-[42px] w-full flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
        </div>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div class="btns flex flex-wrap justify-center items-center gap-[8px] w-full">
          <button
            :disabled="loadCallApi"
            @click="handleDeletePermanentlyAccount"
            class="flex-1 flex justify-center items-center bg-white min-w-[130px] h-[42px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
          >
            <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
              $t("common.account_deletion")
            }}</span>
          </button>
          <button
            :disabled="loadCallApi"
            @click="handleDisableAccount"
            class="flex-1 flex justify-center items-center min-w-[144px] h-[42px] bg-[#F38744] rounded-[8px]"
          >
            <span class="text-white text-[14px] noto500 leading-[18px]">{{
              $t("common.account_hidden")
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ArrowLeftNotLineHos, IconCamera, TrashIcon } from "../../../assets/constant/svg";
import { addModal, getAvatarUserByName, handleImageError } from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import { getToken } from "../../../helpers/token";

const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const loading = ref(true);
const loadCallApi = ref(false);
const loadingUploadImage = ref(false);
const avatar = ref(null);
const userId = ref(null);
const userDetails = computed(() => {
  return store.state.userDetails;
});
const pathNameHistory = computed(() => {
  return "extended-management";
});
const nameHistory = computed(() => {
  return t("common.extended_management");
});
const shopHistory = computed(() => {
  return router.currentRoute.value.query.shop;
});
const pageHistory = computed(() => {
  return router.currentRoute.value.query.page;
});
const backRouter = () => {
  const queryParams = [];
  if (shopHistory.value) {
    queryParams.push(`shop=${shopHistory.value}`);
  }

  if (pageHistory.value) {
    queryParams.push(`page=${pageHistory.value}`);
  }

  return `/${pathNameHistory.value}${
    queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
  }`;
};
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: backRouter(),
    breadcrumbName: nameHistory.value,
  },
  {
    path: `/user-management/${pathNameHistory.value}/user-details-${userDetails.value.status}?id=${userId.value}`,
    breadcrumbName: t("common.personal_info"),
  },
]);

const goBackRouter = () => {
  router.back();
};
const handleFileInputChange = (event) => {
  try {
    loadingUploadImage.value = true;
    const selectedFile = event.target.files[0];
    avatar.value = selectedFile;
    const imageUrl = URL.createObjectURL(selectedFile);
    setTimeout(() => {
      if (document.getElementById("img-avatar")) {
        document.getElementById("img-avatar").src = imageUrl;
      }
      loadingUploadImage.value = false;
    }, 2000);
  } catch (e) {
    throw new Error(e.message);
  } finally {
    setTimeout(() => {
      loadingUploadImage.value = false;
    }, 2000);
  }
};
const handleUploadImage = () => {
  if (!handleUploadImage.value) {
    document.getElementById("user_detail_avatar_upload")?.click();
  }
};
const handleDisableAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch("disableUser", {
        userId: userId.value,
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          store.dispatch("getAllUsers", {
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/user-management/${pathNameHistory.value}/user-details-disable?option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
          loadCallApi.value = false;
        },
      });
    },
    title: t("common.do_you_want_hide_account"),
    textBtnClose: t("common.cancel_delete"),
    textBtnConfirm: t("common.confirm"),
  });
};

const handleDeletePermanentlyAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch("deletePermanentlyUsers", {
        ids: [userId.value],
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          store.dispatch("getAllUsers", {
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/user-management/${pathNameHistory.value}?${
                shopHistory.value ? `shop=${shopHistory.value}` : ""
              }&${pageHistory.value ? `page=${pageHistory.value}` : ""}`
            );
          }, 2000);
          loadCallApi.value = false;
        },
      });
    },
    title: t("common.do_you_want_to_permanently_delete_this_account"),
    textBtnClose: t("common.cancel_delete"),
    textBtnConfirm: t("common.confirm"),
    action: "delete",
  });
};

watch(userId, () => {
  if (userId.value) {
    store.dispatch("getUserDetails", {
      userId: userId.value,
      token: getToken()
    });
  }
});
onMounted(() => {
  userId.value = route.query.id;
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>
<style lang="scss"></style>
