export const shopGetters = {
    areaListGetters: (state) => () => {
        return state.areaList?.map((area) => {
            return {
              label: area.name,
              value: area.name,
              id: area._id,
            };
          }) ?? [];
    },
    getLenShopActive: (state) => () => {
      return state.allShopsAllStatus?.filter((shop) => shop.status === 'active').length ?? 0;
    },
    getLenAllShop: (state) => () => {
      return state.allShopsAllStatus?.length ?? 0;
    }
}