<template>
  <div
    :class="{
      'shop-info-status-item p-7 border-b border-b-gray-border md:border-b-0': true,
      'border-r-0 border-r-gray-border md:border-r': !data?.isHidden,
      'border-b-0': data?.isHidden
    }"
  >
    <div class="top flex items-center justify-between">
      <div class="number text-gray800 text-[32px] noto700">
        {{ data?.number }}
      </div>
      <div
        :class="{
          'icon p-[10px] border rounded-lg bg-ranking-even-background border-orage-text': true,
          '!bg-gray100 !border-gray-border': data?.isHidden,
          relative: data?.isActive
        }"
      >
        <span v-html="data?.icon"></span>
        <span
          v-if="data?.isActive"
          class="absolute right-[-5px] bottom-[-5px]"
          v-html="activeGreenDot"
        ></span>
      </div>
    </div>
    <div class="bottom text-breadcumb-text text-[18px]">
      {{ data?.subtitle }}
    </div>
  </div>
</template>
<script>
import { activeGreenDot } from '../../../../assets/constant/constant';

export default {
  name: 'ShopInfoStatusItem',
  props: {
    data: {
      type: Object
    }
  },
  setup(props) {
    return {
      //icon
      activeGreenDot
    };
  }
};
</script>
<style>
</style>