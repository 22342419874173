<template>
    <div class="flex flex-col justify-center items-start w-[500px] h-[188px] bg-white rounded-[8px]">
        <div class="w-full flex-1 flex flex-col justify-center items-center gap-[4px]">
            <span v-html="ConfirmRedIcon"></span>
            <h3 class="noto700 text-[16px] leading-[24px] text-[#1D2939]">{{ t("common.forced_termination") }}</h3>
            <p class="w-full noto400 text-[16px] leading-[20px] text-center text-[#1D2939] mt-[8px]">{{ t("common.confirm_enable_streaming") }}</p>
        </div>
        <div class="w-full h-[64px] flex justify-center items-center gap-[12px] border-solid border-t-[1px] border-[#D0D5DD] px-[16px] py-[12px]">
            <button @click="handleClose" class="flex-1 h-full border-solid border-[1px] border-[#D0D5DD] rounded-[8px] flex justify-center items-center text-[14px] leading-[18px] noto500 text-[#475467]">{{ t("common.cancel_delete") }}</button>
            <button @click="handleConfirm" class="flex-1 h-full border-solid border-[1px] border-[#F04438] rounded-[8px] flex justify-center items-center  text-[14px] leading-[18px] noto500 text-[#F04438]">STOP</button>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ConfirmRedIcon } from '../../../../assets/constant/svg';

const { t } = useI18n();
const props = defineProps({
    onClose: {
        type: Function,
        required: true,
    },
    onConfirm: {
        type: Function,
        required: true,
    },
    title: {
        type: String,
        default: '',
    },
    textBtnClose: {
        type: String,
        default: '',
    },
    textBtnConfirm: {
        type: String,
        default: '',
    },
    action: {
        type: String,
        default: '',
    }
});
const handleClose = () => {
    props.onClose();
}
const handleConfirm = () => {
    props.onClose();
    props.onConfirm();
}
</script>

<style lang="scss"></style>