<template>
  <div ref="zoneMenu" v-if="false" class="menu-tab relative flex justify-start items-center mb-[14px]">
    <span
      v-html="MenuIcon"
      class="cursor-pointer"
      @click="handleChangeStatusChildItem"
    ></span>
    <div
      v-if="openChildItem"
      class="panel-menu-item absolute flex flex-col justify-start items-start top-[28px] left-[0px] w-[240px] h-[156px] bg-[#1D2939] z-[1000] rounded-[12px]"
    >
      <div
        v-for="menu in menus"
        :class="{
          'w-full flex justify-start items-center h-[52px] px-[24px] py-[12px] noto500 text-[16px] leading-[20px] text-[#667085]': true,
          'border-y-[1px] border-solid border-[#667085]': menu.id === 2,
          'text-white': itemSubMenuFocus === menu.id,
        }"
        @click="handleFocusItemSubMenu(menu.id, menu.path)"
      >
        {{ menu.name }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { MenuIcon } from "../../../../assets/constant/svg";
import { useI18n } from "vue-i18n";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
const router = useRouter();
const store = useStore();
const { t } = useI18n();
const props = defineProps({
  index: {
    type: Number,
    default: 3,
  },
});
const zoneMenu = ref(null);
const menus = ref([
  {
    id: 1,
    name: t("common.total_number_of_users"),
    path: "/user-management/total-users",
  },
  {
    id: 2,
    name: t("common.users_by_store"),
    path: "/user-management/users-by-store",
  },
  {
    id: 3,
    name: t("common.account_permission"),
    path: "/user-management/account-permission",
  },
]);
const openChildItem = ref(false);
const itemSubMenuFocus = computed(() => {
  return store.getters.getSidebarItemChildFocus(props.index);
});
const handleChangeStatusChildItem = () => {
  localStorage.setItem(
    "openChildItemKey" + props.index,
    openChildItem.value ? "close" : "open"
  );
  openChildItem.value = !openChildItem.value;
};
const handleFocusItemSubMenu = (index, link) => {
  store.dispatch("setSidebarFocus", props.index);
  store.dispatch("setSidebarItemChildFocus", {
    key: props.index,
    index: index,
  });
  if (!router.currentRoute.value.fullPath.includes(link)) {
    router.push(link);
  }
};
const handleClickOutside = (event) => {
  if (zoneMenu.value && !zoneMenu.value.contains(event.target)) {
    openChildItem.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>
