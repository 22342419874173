<template>
  <div ref="zoneSelectShop" v-if="!layoutMobile" class="box-search-combobox relative">
    <button
      class="min-w-[210px] h-[44px] flex justify-center items-center gap-[12px] rounded-[8px] bg-white px-[20px] py-[12px] border-solid border-[1px] border-[#D0D5DD]"
      v-on:click="handleChangeStatusBoxSearch"
    >
      <span v-html="StoreIconBlack"></span>
      <span class="text-black w-fit text-[14px] noto500 leading-[18px] ">{{
        shortenString(shopSelected?.name, 15) || "-"
      }}</span>
      <span v-if="!openBoxSearch" v-html="ArrowDownGray"></span>
      <span v-if="openBoxSearch" v-html="ArrowUpGray"></span>
    </button>
    <div
      :class="{
        'box-search z-[50] flex flex-col justify-start items-center gap-[8px] absolute min-w-[200px] max-w-[250px] min-h-[250px] top-[48px] left-[50%] translate-x-[-50%] rounded-[8px] p-[8px] overflow-hidden border-[#F2F4F7] border-solid border-[1px] bg-white ': true,
        hidden: !openBoxSearch,
      }"
      style="box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14); width: max-content"
    >
      <label
        class="box-input w-full h-[40px] rounded-[8px] flex justify-start items-center gap-[8px] bg-white border-solid border-[1px] border-[#D0D5DD]"
      >
        <span
          class="w-[20px] h-full flex justify-center items-center ml-[8px]"
          v-html="SearchIconSmall"
        ></span>
        <input
          v-model="searchShopKey"
          type="text"
          class="max-w-[160px] mr-[5px] h-full outline-none text-[14px] noto400"
          placeholder=""
        />
      </label>
      <div
        class="panel-list-store overflow-y-auto scroll-bar scroll-bar-small w-auto max-h-[200px] translate-x-[2px] pl-[10px]"
      >
        <div class="list-store flex flex-col justify-start items-center w-full gap-[8px]">
          <div
            v-if="!loadingSearchShop && listShopRender && listShopRender.length > 0"
            class="list-store flex flex-col justify-start items-center w-full gap-[8px]"
          >
            <button
              type="button"
              :class="{
                'w-full min-w-[230px] h-[40px] translate-x-[-5px] flex justify-start items-center gap-[8px] px-[12px] py-[8px] rounded-[8px] whitespace-nowrap hover:bg-[#f19b65]': true,
                '!bg-[#F38744]': shopSelected?._id === shop?._id,
              }"
              v-for="shop in listShopRender"
              v-on:click="onSelectShop(shop)"
            >
              <span
                v-html="shopSelected?._id === shop?._id ? StoreIconWhite : StoreIconBlack"
              ></span>
              <span
                :class="{
                  'text-[14px] noto400 leading-[20px] max-w-[170px] overflow-hidden': true,
                  'text-white': shopSelected?._id === shop?._id,
                }"
                >{{ shortenString(shop?.name, 20) || "-" }}</span
              >
            </button>
          </div>
          <div
            v-if="loadingSearchShop"
            class="list-store translate-x-[-10px] min-full h-[180px] flex flex-col justify-center items-center"
          >
            <a-spin :indicator="indicatorLoadingSearchShop" />
          </div>
          <div
            v-if="!loadingSearchShop && listShopRender && listShopRender.length <= 0"
            class="list-store translate-x-[-10px] min-full h-[180px] flex flex-col justify-center items-center"
          >
            <EmptyDataSmall />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ref="zoneSelectShop" v-else class="box-search-combobox relative w-full">
    <button
      class="min-w-[210px] w-full h-[44px] flex justify-center items-center gap-[12px] rounded-[8px] bg-white px-[20px] py-[12px] border-solid border-[1px] border-[#D0D5DD]"
      v-on:click="handleChangeStatusBoxSearch"
    >
      <span v-html="StoreIconBlack"></span>
      <span class="text-black text-[14px] noto500 leading-[18px]">{{
        shortenString(shopSelected?.name, 15) || "-"
      }}</span>
      <span v-html="openBoxSearch ? ArrowUpGray : ArrowDownGray"></span>
    </button>
    <div
      :class="{
        'box-search z-[50] flex flex-col justify-start items-center gap-[8px] absolute min-w-[200px] w-full min-h-[250px] top-[48px] left-[50%] translate-x-[-50%] rounded-[8px] p-[8px] overflow-hidden border-[#F2F4F7] border-solid border-[1px] bg-white ': true,
        hidden: !openBoxSearch,
      }"
      style="box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14)"
    >
      <label
        class="box-input w-full h-[40px] rounded-[8px] flex justify-start items-center gap-[8px] bg-white border-solid border-[1px] border-[#D0D5DD]"
      >
        <span
          class="w-[20px] h-full flex justify-center items-center ml-[8px]"
          v-html="SearchIconSmall"
        ></span>
        <input
          v-model="searchShopKey"
          type="text"
          class="max-w-[160px] mr-[5px] h-full outline-none text-[14px] noto400"
          placeholder=""
        />
      </label>
      <div
        class="panel-list-store overflow-y-auto scroll-bar scroll-bar-small !w-full max-h-[200px] translate-x-[2px] pl-[10px]"
      >
        <div class="list-store flex flex-col justify-start items-center w-full gap-[8px]">
          <div
            v-if="!loadingSearchShop && listShopRender && listShopRender.length > 0"
            class="list-store flex flex-col justify-start items-center w-full gap-[8px]"
          >
            <button
              type="button"
              :class="{
                'w-full min-w-[230px] h-[40px] translate-x-[-5px] flex justify-start items-center gap-[8px] px-[12px] py-[8px] rounded-[8px] whitespace-nowrap hover:bg-[#f19b65]': true,
                '!bg-[#F38744]': shopSelected?._id === shop?._id,
              }"
              v-for="shop in listShopRender"
              v-on:click="onSelectShop(shop)"
            >
              <span
                v-html="shopSelected?._id === shop?._id ? StoreIconWhite : StoreIconBlack"
              ></span>
              <span
                :class="{
                  'text-[14px] noto400 leading-[20px] max-w-[170px] overflow-hidden': true,
                  'text-white': shopSelected?._id === shop?._id,
                }"
                >{{ shortenString(shop?.name, 20) || "-" }}</span
              >
            </button>
          </div>
          <div
            v-if="loadingSearchShop"
            class="list-store translate-x-[-10px] min-full h-[180px] flex flex-col justify-center items-center"
          >
            <a-spin :indicator="indicatorLoadingSearchShop" />
          </div>
          <div
            v-if="!loadingSearchShop && listShopRender && listShopRender.length <= 0"
            class="list-store translate-x-[-10px] translate-y-[-20px] min-full h-[180px] flex flex-col justify-center items-center"
          >
            <EmptyDataMedium />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onBeforeUnmount, onMounted, ref, watch } from "vue";
import {
  containsNonWhitespaceCharacters,
  shortenString,
  useDebounce,
} from "../../../../helpers";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  ArrowDownGray,
  ArrowUpGray,
  SearchIconSmall,
  StoreIconBlack,
  StoreIconWhite,
} from "../../../../assets/constant/svg";
import EmptyDataSmall from "../../../master/EmptyDataSmall.vue";
import EmptyDataMedium from "../../../master/EmptyDataMedium.vue";
const store = useStore();
const route = useRoute();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  handleSelectShop: {
    type: Function,
    required: true,
  },
});
const zoneSelectShop = ref(null);
const loadingSearchShop = ref(false);
const indicatorLoadingSearchShop = h(LoadingOutlined, {
  style: {
    fontSize: "20px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const searchShopKey = ref("");
const openBoxSearch = ref(false);
const shopSelected = ref(null);
const listShop = computed(() => store.state.allShops);
const listShopRender = ref([]);
const handleChangeStatusBoxSearch = (event) => {
  event.stopPropagation();
  openBoxSearch.value = !openBoxSearch.value;
};
const onSelectShop = (shop) => {
  shopSelected.value = shop;
  props.handleSelectShop(shop);
  openBoxSearch.value = false;
};
const searchShopRender = (searchKey) => {
  if (!searchKey || !containsNonWhitespaceCharacters(searchKey)) {
    listShopRender.value = listShop.value;
  } else {
    const searchToLowerCase = searchKey.toLowerCase();
    const searchResult = listShop.value?.filter((shop) => {
      return (
        searchToLowerCase.includes(shop.name?.toLowerCase()) ||
        shop.name?.toLowerCase().includes(searchToLowerCase)
      );
    });
    listShopRender.value = searchResult;
  }
  loadingSearchShop.value = false;
};
const searchShopRenderDebounce = useDebounce(searchShopRender, 1000);
watch(searchShopKey, () => {
  loadingSearchShop.value = true;
  searchShopRenderDebounce(searchShopKey.value);
});
watch(listShop, () => {
  searchShopRenderDebounce(searchShopKey.value);
  const shop = route.query.shop;
  if (shop) {
    const shopMatch = listShop.value.find((item) => {
      return item._id === shop;
    });
    if (shopMatch) {
      onSelectShop(shopMatch);
    } else {
      onSelectShop(listShop.value[0]);
    }
  } else {
    onSelectShop(listShop.value[0]);
  }
});
const handleClickOutside = (event) => {
  if (zoneSelectShop.value && !zoneSelectShop.value.contains(event.target)) {
    openBoxSearch.value = false;
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
onMounted(() => {
  store.dispatch("getAllShops");
  document.addEventListener("click", handleClickOutside);
});
</script>
