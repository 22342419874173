export const uisState = {
  collapseSidebar: false,
  hideSidebar: false,
  sidebarFocus: localStorage.getItem('sidebarFocus')
    ? Number(localStorage.getItem('sidebarFocus'))
    : 1,
  loading: false,
  updatePasswordSetting: false,
  sidebarItemChildFocus: [],
  layoutMobile: false
};
