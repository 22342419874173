<template>
  <div
    v-if="!layoutMobile"
    class="stores-container bg-[#F9FAFB] px-[24px] py-[21px] pb-[250px]"
  >
    <div class="stores-header flex justify-between h-fit w-full">
      <div class="stores-header-left">
        <span class="text-header-bold text-[24px] noto700">
          {{ $t("common.stores_management") }}
        </span>
        <div class="store-breadcrumb pt-[14px] pb-[24px]">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <label
        for="search-input"
        class="stores-header-middle w-fit h-fit mt-[20px] flex gap-2 items-center rounded-lg pl-4 border border-gray-border bg-white cursor-text"
      >
        <label class="cursor-text" for="search-input" v-html="searchIcon"></label>
        <input
          id="search-input"
          class="outline-0 py-[10px] noto400"
          type="text"
          v-model="searchKey"
          autocomplete="off"
          @keydown.enter="searchShopRender(searchKey)"
          :placeholder="$t('common.explore_the_store')"
        />
        <button
          class="text-white bg-orage-text border-l border-l-gray-border h-[44px] px-3 rounded-r-[7px] whitespace-nowrap"
          @click="searchShopRender(searchKey)"
        >
          {{ $t("common.search_master") }}
        </button>
      </label>
      <div class="stores-header-right mt-[20px] flex justify-center gap-[12px]">
        <button
          class="btn-request-approval flex justify-center items-center gap-[6px] min-w-[156px] h-[44px] text-[#F38744] bg-white rounded-[8px] px-[16px] py-[10px] border-[1px] border-solid border-[#F38744]"
          @click="handleNavigationListShopApproval"
        >
          <span>{{ $t("common.new_request_approval") }}</span>
          <div class="relative w-[20px] h-[20px]">
            <span v-html="NotificationIcon"></span>
            <span
              v-if="shopRequestList?.length > 0"
              class="absolute flex justify-center items-center top-0 right-0 translate-x-[50%] translate-y-[-50%] w-[18px] h-[18px] rounded-full bg-[#F04438] text-white text-[10px] noto700 leading-[18px]"
              >{{ shopRequestList?.length }}</span
            >
          </div>
        </button>
        <button
          class="btn-request-approval w-[114px] h-[44px] rounded-[8px] flex justify-center items-center gap-[6px] bg-[#F38744] border-[1px] border-solid border-[#F38744]"
          @click="handleCreatedShop"
        >
          <span v-html="PlusWhiteIcon"></span>
          <span class="noto500 text-[14px] leading-[18px] text-white">{{
            $t("common.stores_creation")
          }}</span>
        </button>
      </div>
    </div>
    <div class="stores-table gap flex flex-wrap gap-6 pb-4">
      <ShopItem
        v-if="listShopRender.length > 0"
        v-for="(item, index) in listShopRender"
        :key="index"
        :data="item"
      />
      <div v-else class="w-full h-[70vh] flex justify-center items-center">
        <EmptyData />
      </div>
    </div>
  </div>
  <div
    v-else
    class="stores-container-mobile bg-[#F9FAFB] flex flex-col justify-start items-start px-[24px] py-[21px] pb-[250px]"
  >
    <div class="header w-full flex justify-between items-center gap-[20px]">
      <div class="left w-full">
        <span class="text-header-bold text-[24px] noto700">
          {{ $t("common.stores_management") }}
        </span>
        <div class="store-breadcrumb pt-[14px] pb-[24px]">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="right w-full btns flex justify-end items-center gap-[12px]">
        <button
          class="btn-request-approval flex justify-center items-center gap-[6px] min-w-[52px] h-[44px] text-[#F38744] bg-white rounded-[8px] px-[16px] py-[10px] border-[1px] border-solid border-[#F38744]"
          @click="handleNavigationListShopApproval"
        >
          <div class="relative w-[20px] h-[20px]">
            <span v-html="NotificationIcon"></span>
            <span
              v-if="shopRequestList?.length > 0"
              class="absolute flex justify-center items-center top-0 right-0 translate-x-[50%] translate-y-[-50%] w-[18px] h-[18px] rounded-full bg-[#F04438] text-white text-[10px] noto700 leading-[18px]"
              >{{ shopRequestList?.length }}</span
            >
          </div>
        </button>
        <button
          class="btn-request-approval w-[52px] h-[44px] rounded-[8px] flex justify-center items-center gap-[6px] bg-[#F38744] border-[1px] border-solid border-[#F38744]"
          @click="handleCreatedShop"
        >
          <span v-html="PlusWhiteIcon"></span>
        </button>
      </div>
    </div>
    <div class="search w-full h-[44px] mb-[24px] flex justify-center items-center">
      <label
        for="search-input"
        class="stores-header-middle w-full h-fit flex gap-2 items-center rounded-lg pl-4 border border-gray-border bg-white cursor-text"
      >
        <label class="cursor-text" for="search-input" v-html="searchIcon"></label>
        <input
          id="search-input"
          class="flex-1 outline-0 py-[10px] noto400"
          type="text"
          v-model="searchKey"
          autocomplete="off"
          @keydown.enter="searchShopRender(searchKey)"
          :placeholder="$t('common.explore_the_store')"
        />
        <button
          class="text-white bg-orage-text border-l border-l-gray-border h-[44px] px-3 rounded-r-[7px] whitespace-nowrap"
          @click="searchShopRender(searchKey)"
        >
          {{ $t("common.search_master") }}
        </button>
      </label>
    </div>
    <div class="stores-table w-full flex justify-start items-center flex-wrap gap-6 pb-4">
      <ShopItem
        v-if="listShopRender.length > 0"
        v-for="(item, index) in listShopRender"
        :key="index"
        :data="item"
      />
      <div v-else class="w-full h-[70vh] flex justify-center items-center">
        <EmptyData />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { searchIcon } from "../../../assets/constant/constant";
import { NotificationIcon, PlusWhiteIcon } from "../../../assets/constant/svg";
import Breadcrumd from "../../master/Breadcrumd.vue";
import EmptyData from "../../master/EmptyData.vue";
import ShopItem from "./components/ShopItem.vue";
import { useDebounce } from "../../../helpers";
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const layoutMobile = computed(() => store.state.layoutMobile);
const listShop = computed(() => {
  const statusOrder = { active: 1, disable: 2 };
  return store.state.allShopsAllStatus?.sort(
    (a, b) => statusOrder[a.status] - statusOrder[b.status]
  );
});
const shopRequestList = computed(() => {
  return store.state.shopRequestList;
});
const listShopRender = ref([]);
const searchKey = ref("");
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/store-management",
    breadcrumbName: t("common.stores_management"),
  },
]);

const handleNavigationListShopApproval = () => {
  router.push("/store-management/store-request");
};
const handleCreatedShop = () => {
  router.push("/store-management/create-store");
};
const searchShopRender = (searchKey) => {
  if (searchKey === "") {
    listShopRender.value = listShop.value;
  } else {
    const searchToLowerCase = searchKey.toLowerCase();
    const searchResult = listShop.value?.filter((shop) => {
      return (
        searchToLowerCase.includes(shop.name?.toLowerCase()) ||
        shop.name?.toLowerCase().includes(searchToLowerCase)
      );
    });
    listShopRender.value = searchResult;
  }
};
const searchShopRenderDebounce = useDebounce(searchShopRender, 500);
watch(searchKey, () => {
  searchShopRenderDebounce(searchKey.value);
});
watch(listShop, () => {
  if (listShopRender && listShopRender.value.length <= 0) {
    listShopRender.value = listShop.value;
  }
});
onMounted(() => {
  store.dispatch("getAllShopAllStatus");
  store.dispatch("getShopRequestList");
  searchShopRender(searchKey.value);
});
</script>
<style lang="scss">
.btn-request-approval {
  &:hover {
    background-color: #f38744;
    span {
      color: white;
    }
    svg {
      path {
        fill: white;
        stroke: white;
      }
    }
  }
}
.stores-header {
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    .stores-header-left {
      align-self: flex-start;
    }
    .stores-header-middle {
      margin: 0;
    }
    .stores-header-right {
      margin: 10px 0;
    }
  }
}
</style>
