export const uisMutation = {
  UPDATE_STATUS_COLLAPSE_SIDEBAR(state, status) {
    state.collapseSidebar = status || !state.collapseSidebar;
  },

  UPDATE_STATUS_HIDE_SIDEBAR(state, status) {
    state.hideSidebar = status;
  },

  UPDATE_SIDEBAR_FOCUS(state, index) {
    state.sidebarFocus = index;
  },
  UPDATE_LOADING(state, status) {
    state.loading = status;
  },
  UPDATE_SIDEBAR_ITEM_CHILD_FOCUS(state, props){
    let flag = false;
    state.sidebarItemChildFocus = state.sidebarItemChildFocus?.map((item) => {
      if(props.key === item.key){
        flag = true;
        return {...item, index: props.index};
      }else {
        return item;
      }
    });
    if(!flag){
      state.sidebarItemChildFocus.push({key: props.key, index: props.index});
    }
  },
  UPDATE_LAYOUT_MOBILE(state, payload) {
    state.layoutMobile = payload;
  }
}