<template>
  <div
    class="shop-request-approval h-[110vh] min-h-screen px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.store_approval") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
    </div>
    <div
      class="w-full border-solid border-[#D0D5DD] border-[1px] rounded-[12px] mt-[24px] mb-[200px] bg-white overflow-hidden"
    >
      <div
        class="header flex justify-start items-center h-[68px] bg-[#475467] px-[24px] py-[12px] rounded-tl-[12px] rounded-tr-[12px]"
      >
        <p class="text-white noto700 text-[20px] leading-[28px]">
          {{ shopRequestList.length ?? 0 }} {{ $t("common.request") }}
        </p>
      </div>
      <div
        class="bg-white flex flex-wrap justify-center items-start w-full overflow-y-auto max-h-[75vh] scroll-bar scroll-bar-small px-[10px] py-[20px] gap-[24px] rounded-[12px]"
      >
        <div
          v-if="shopRequestList && shopRequestList.length > 0"
          class="list-shop-request-appvoral w-full flex flex-wrap justify-center items-start gap-[24px]"
        >
          <ShopItemReqApproval
            v-for="shop in shopRequestList"
            :shop="shop"
            :key="shop?._id"
          />
        </div>
        <div
          v-else
          class="list-shop-request-appvoral w-full h-[50vh] flex flex-wrap justify-center items-center gap-[24px]"
        >
          <EmptyData />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { addModal } from "../../../helpers";
import store from "../../../store";
import Breadcrumd from "../../master/Breadcrumd.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import EmptyData from "../../master/EmptyData.vue";
import ShopItemReqApproval from "./components/ShopItemReqApproval.vue";
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/store-management",
    breadcrumbName: t("common.store_management"),
  },
  {
    path: "/store-management/store-request",
    breadcrumbName: t("common.store_approval"),
  },
]);
const shopRequestList = computed(() => {
  return store.state.shopRequestList;
});
onMounted(() => {
  store.dispatch("getShopRequestList");
});
</script>
<style lang="scss" scoped></style>
