import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';
import { ENCODE_SECRET_KEY } from '../constants';

export function getToken() {
  if (VueCookies.get('access_token_master')) {
    const tokenEncode = VueCookies.get('access_token_master');
    const bytes = CryptoJS.AES.decrypt(tokenEncode, ENCODE_SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
}
