<template>
  <div v-if="!layoutMobile"
    class="w-[800px] min-h-[486px] rounded-[12px] overflow-hidden flex justify-start items-center flex-col"
  >
    <div
      class="header w-full noto400 text-[14px] leading-[22px] text-[#1D2939] h-[70px] px-[28px] py-[20px] bg-[#FFFFFF] flex justify-between items-center border-b-[1px] border-[#D0D5DD] border-solid"
    >
      <h3 class="noto700 text-[20px] leading-[30px] text-[#1D2939]">
        {{ $t("common.more_info") }}
      </h3>
      <button
        class="noto500 text-[13px] leading-[24px] text-[#667085]"
        @click="props.onClose()"
      >
        {{ $t("common.close") }}
      </button>
    </div>
    <div
      class="w-full noto400 text-[14px] leading-[22px] text-[#1D2939] h-[416px] flex justify-start items-start flex-col gap-[28px] bg-white px-[28px] py-[20px]"
    >
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex justify-start items-center gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_name")
        }}</label>
        <input
          readonly
          :value="shop?.name"
          class="flex-1 h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex justify-start items-center gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_telephone_number")
        }}</label>
        <input
          readonly
          :value="shop?.phone"
          class="flex-1 h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex justify-start items-center gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.address")
        }}</label>
        <input
          readonly
          :value="shop?.address"
          class="flex-1 h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input relative w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex justify-start items-center gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.area")
        }}</label>
        <input
          readonly
          :value="areaNameOfShop"
          class="h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none pr-[30px] border-b-[1px] border-solid border-[#d0d5dd]"
          style="width: calc(100% - 100px)"
          type="text"
        />
        <span class="absolute right-[8px] top-[13px]" v-html="ArrowDownSelect"></span>
      </div>
      <div
        class="box-input min-h-[80px] w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex justify-start items-center gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_logo")
        }}</label>
        <img
          :src="getAvatarShopByName(shop?.avatar)"
          alt=""
          @error="(e) => handleImageError(e, 'shop')"
          class="w-[80px] h-[80px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD]"
        />
      </div>
    </div>
  </div>
  <div v-else
    class="w-[800px] min-h-[646px] rounded-[12px] overflow-hidden flex justify-start items-center flex-col"
  >
    <div
      class="header w-full noto400 text-[14px] leading-[22px] text-[#1D2939] h-[70px] px-[28px] py-[20px] bg-[#FFFFFF] flex justify-between items-center border-b-[1px] border-[#D0D5DD] border-solid"
    >
      <h3 class="noto700 text-[20px] leading-[30px] text-[#1D2939]">
        {{ $t("common.more_info") }}
      </h3>
      <button
        class="noto500 text-[13px] leading-[24px] text-[#667085]"
        @click="props.onClose()"
      >
        {{ $t("common.close") }}
      </button>
    </div>
    <div
      class="w-full noto400 text-[14px] leading-[22px] text-[#1D2939] h-[576px] flex justify-start items-start flex-col gap-[28px] bg-white px-[28px] py-[20px]"
    >
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex flex-col justify-start items-start gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_name")
        }}</label>
        <input
          readonly
          :value="shop?.name"
          class="w-full h-full noto400 text-[14px] rounded-0 leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex flex-col justify-start items-start gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_telephone_number")
        }}</label>
        <input
          readonly
          :value="shop?.phone"
          class="w-full rounded-0 h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex flex-col justify-start items-start gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.address")
        }}</label>
        <input
          readonly
          :value="shop?.address"
          class="w-full rounded-0 h-full noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
      </div>
      <div
        class="box-input relative w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex flex-col justify-start items-start gap-[8px] h-[78px]"
      >
        <label class="w-full text-left noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.area")
        }}</label>
        <input
          readonly
          :value="areaNameOfShop"
          class="h-full w-full rounded-0 noto400 text-[14px] leading-[22px] text-[#1D2939] outline-none pr-[30px] border-b-[1px] border-solid border-[#d0d5dd]"
          type="text"
        />
        <span class="absolute right-[8px] top-[45px]" v-html="ArrowDownSelect"></span>
      </div>
      <div
        class="box-input min-h-[80px] w-full noto400 text-[14px] leading-[22px] text-[#1D2939] flex flex-col justify-start items-start gap-[8px] h-[78px]"
      >
        <label class="min-w-[110px] noto500 text-[16px] leading-[24px] text-[#667085]">{{
          $t("common.store_logo")
        }}</label>
        <img
          :src="getAvatarShopByName(shop?.avatar)"
          alt=""
          @error="(e) => handleImageError(e, 'shop')"
          class="w-[80px] h-[80px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD]"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { ArrowDownSelect } from "../../../../assets/constant/svg";
import { getAvatarShopByName, handleImageError } from "../../../../helpers/index";
import { useStore} from "vuex";
const store = useStore();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
  shop: {
    type: Object,
    required: true,
  },
  areaList: {
    type: Array,
    required: true,
  },
});

const areaNameOfShop = computed(() => {
  return props?.areaList?.find((area) => {
    return area.id === props.shop.location;
  })?.value;
});
</script>
<style lang="css" scoped>
input{
  border-radius: 0px !important;
}
</style>

