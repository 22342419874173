<template>
  <div
    class="flex flex-col justify-center items-start w-[600px] h-[128px] bg-white rounded-[8px]"
  >
    <div
      class="title flex justify-center items-center border-b-[1px] border-solid border-[#D0D5DD] w-full h-[72px]"
    >
      <span class="text-[#1D2939] text-[16px] noto700 leading-[24px]">{{
        title ?? ''
      }}</span>
    </div>
    <div class="btns max-h-[56px] flex justify-start items-center w-full h-[56px]">
      <button
        v-on:click="handleClose"
        class="cancel !max-h-[56px] flex-1 h-full text-[#667085] text-[14px] noto500 leading-[18px] border-r-[1px] border-solid border-[#D0D5DD]"
      >
        {{ textBtnClose ?? '' }}
      </button>
      <button
        v-on:click="handleConfirm"
        :class="{'allow !max-h-[56px] flex-1 h-full text-[#F38744] text-[14px] noto500 leading-[18px]': true, '!text-[#F04438]': action === 'delete'}"
      >
        {{ textBtnConfirm ?? '' }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  textBtnClose: {
    type: String,
    default: '',
  },
  textBtnConfirm: {
    type: String,
    default: '',
  },
  action: {
    type: String,
    default: '',
  }
});
const handleClose = () => {
  props.onClose();
}
const handleConfirm = () => {
  props.onClose();
  props.onConfirm();
}
</script>

<style lang="scss"></style>
