import { showAlert } from '../../helpers';
export const authsMutation = {
  UNAUTHORIZED_USER_MODAL() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Your username or password is incorrect.'
        : 'ユーザー名またはパスワードが違います。',
      'top-center'
    );
  },
  LOGIN(state, payload) {
    state.userLogin = payload;
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
      ? 'Login successfully'
      : 'ログインできました。',
      'top-center'
    );
    setTimeout(() => {
      payload.navigator();
    }, 2000);
  },
  
};
