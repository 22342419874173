<template la>
  <div class="dashboard-container px-[24px] py-[21px] bg-[#F9FAFB] scroll-bar">
    <span class="text-header-bold text-[24px] noto700">
      {{ $t("common.dashboard") }}
    </span>
    <div class="dashboard-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <div class="shop-info border border-gray-border rounded-lg bg-white">
      <div
        class="shop-info-header border-b border-b-gray-border text-center py-5 text-gray600 noto700"
      >
        {{ $t("common.store_info") }}
      </div>
      <div class="shop-info-status grid grid-cols-1 md:grid-cols-3">
        <ShopInfoStatusItem
          v-for="(item, index) in ShopStatusItemData"
          :key="index"
          :data="item"
        />
      </div>
    </div>
    <div class="total-user border border-gray-border rounded-lg mt-6 bg-white">
      <div
        class="total-user-header border-b border-b-gray-border text-center py-5 text-gray600 noto700"
      >
        {{ $t("common.total_number_of_users") }}({{ totalUser }})
      </div>
      <div
        class="total-user-table flex-wrap flex gap-6 p-5 w-full max-h-[470px] overflow-auto scroll-bar"
      >
        <UserItem
          v-if="userInShopItemData.length > 0"
          v-for="(item, index) in userInShopItemData"
          :key="index"
          :data="item"
        />
        <div v-else class="w-full min-h-[400px] flex justify-center items-center">
          <EmptyData />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { hiddenHomeIcon, primaryHomeIcon } from "../../../assets/constant/constant.js";
import Breadcrumd from "../../master/Breadcrumd.vue";
import EmptyData from "../../master/EmptyData.vue";
import ShopInfoStatusItem from "./components/ShopInfoStatusItem.vue";
import UserItem from "./components/UserItem.vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { getToken } from "../../../helpers/token.js";

const { t } = useI18n();
const store = useStore();
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/dashboard",
    breadcrumbName: t("common.dashboard"),
  },
]);
const lenShopActive = computed(() => {
  return store.getters.getLenShopActive();
});
const lenAllShop = computed(() => {
  return store.getters.getLenAllShop();
});
const ShopStatusItemData = computed(() => {
  return [
    {
      number: lenAllShop.value,
      icon: primaryHomeIcon,
      subtitle: t("common.total_number_of_store"),
    },
    {
      number: lenShopActive.value,
      icon: primaryHomeIcon,
      isActive: true,
      subtitle: t("common.store_under_contract"),
    },
    {
      number: lenAllShop.value - lenShopActive.value,
      icon: hiddenHomeIcon,
      isHidden: true,
      subtitle: t("common.hidden_store"),
    },
  ];
});

const userInShopItemData = computed(() => {
  return store.getters.allUserListSorted();
});
const totalUser = computed(() => userInShopItemData.value.length);

onMounted(() => {
  store.dispatch("getAllShopAllStatus", {
    token: getToken()
  });
  store.dispatch("getAllUsers", {
    token: getToken()
  });
});
</script>
